import React from 'react';
import logo from './logo.svg';
import './App.css';
import Section1 from './components/Section';

function App() {
  return (
    <div className="App">
      <Section1/>
    </div>
  );
}

export default App;
